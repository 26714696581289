import request from './request'

const api = {
	// 地区列表
	lists: '/District/index',
	// 新增
	add: '/District/create',
	// 删除
	dele: '/District/delete',
	// 地区详情
	read: '/District/read',
	// 编辑
	edit: '/District/update',
}

export {
	request,
	api
}
